import React from 'react';
import useMarketingParams from '../../hooks/useMarketingParams';

const UtmParamsFields = () => {
  const marketingParams = useMarketingParams();
  const { utm_source, utm_medium, utm_campaign, utm_term, gclid } =
    marketingParams;

  return (
    <>
      <input type="hidden" name="url" value="Get Estimation" />
      <input type="hidden" name="source" id="utm_source" value={utm_source || ''} />
      <input type="hidden" name="medium" id="utm_medium" value={utm_medium || ''} />
      <input type="hidden" name="campaign" id="utm_campaign" value={utm_campaign || ''} />
      <input type="hidden" name="term" id="utm_term" value={utm_term || ''} />
      <input type="hidden" name="clientId" id="clientId" value={gclid || ''} />
    </>
  );
};

export default UtmParamsFields;
