import React from 'react';
import { Box, Typography } from '@mui/material';
import theme from '../theme/Theme';
import Button from '../components/UI/Button/Button';

const NoServiceThankYou = () => {
  const projectsPageRedirect = () => {
    window.open('https://www.myhomeus.com/project-gallery/', '_blank');
  };

  return (
    <>
      <Box
        sx={{
          paddingTop: '50px',
          marginBottom: '50px',
          [theme.breakpoints.down('sm')]: {
            paddingTop: '24px'
          }
        }}
      >
        <Typography
          variant="h1"
          align="center"
          sx={{
            [theme.breakpoints.down('sm')]: {
              marginBottom: '50px',
            },
          }}
        >
          You're In 🎉
        </Typography>

        <Typography
          variant="subtitle1"
          align="center"
          sx={{
            marginBottom: '50px',
            maxWidth: '720px',
            margin: '0 auto',
          }}
        >
          If you haven't done so yet, how about visiting our projects gallery for more renovation inspiration?
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" mb={3}>
        <Button
          text="Take me there!"
          sx={{
            padding: '15px',
            '&&': {
              maxWidth: '320px',
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '180px',
              },
            },
          }}
          onClick={projectsPageRedirect}
        />
      </Box>
    </>
  );
};

export default NoServiceThankYou;
