import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOnPressEnter } from '../hooks/useOnPressEnter';
import { Typography, Box, TextField } from '@mui/material';
import Button from '../components/UI/Button/Button';
import { FIELDS } from '../consts/fields';
import { validateField } from '../utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { pagesActions } from '../redux/pages-slice';
import { sendGtagEvent, validateZipCode } from '../utils/analytics';
import theme from '../theme/Theme';
import merliynRep from '../assets/get_avatar.jpg';

const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = location.search || '';
  const { zipcode } = useSelector((state) => state.pages.welcome);
  const { isMobile } = useSelector((state) => state.pages.global);
  const { setZipcode } = pagesActions;
  const [zipCodeError, setZipCodeError] = useState('');

  const onZipCodeChange = (e) => {
    const { value } = e.target;
    dispatch(setZipcode(value));
  };

  const onClickNext = async () => {
    const isFieldValid = validateField(FIELDS.zipCode.validationField, zipcode);

    if (!zipcode || !isFieldValid) {
      setZipCodeError('Please enter a valid zipcode');
      return;
    }

    const { isValid: isZipCodeSupported } = await validateZipCode(zipcode);
    console.log("validateZipCode");

    if (isZipCodeSupported) {
      const gtagData = {
        event: 'mhZipSubmitted',
        zip: zipcode,
      };

      sendGtagEvent(gtagData);
      navigate(`/rooms`);
    } else {
      navigate(`/no-service`);
    }
  };

  useOnPressEnter(onClickNext);

  return (
    <Box
      sx={{
        paddingTop: '50px',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '40px',
        },
      }}
    >
      {isMobile && (
        <Box
          sx={{
            marginRight: '0',
            marginBottom: '20px',

            '& img': {
              borderRadius: '50%',
              margin: '0 auto',
              display: 'block',
              width: '80px'
            },
          }}
        >
          <img src={merliynRep} alt="" />
        </Box>
      )}

      <Typography variant="subtitle1" align="center">
        Hi, I’m Merilin.
      </Typography>
      <Typography
        variant="h1"
        align="center"
        sx={{
          marginBottom: '33px',
          [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
          },
        }}
      >
        Where are you remodeling?
      </Typography>

      <Box
        display="flex"
        justifyContent="center"
        mb={3}
        sx={{
          [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
          },
        }}
      >
        <TextField
          id="outlined-required"
          label="Zip-Code"
          color="orange"
          inputProps={{ maxLength: 10 }}
          variant="outlined"
          onChange={onZipCodeChange}
          error={!!zipCodeError}
          helperText={zipCodeError}
          value={zipcode}
        />
      </Box>

      <Typography
        variant="body1"
        align="center"
        sx={{
          marginBottom: '40px',
          color: 'greys.dark',
          [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            marginBottom: '50px',
          },
        }}
      >
        MyHome currently serves New York City.
      </Typography>
      

      <Box display="flex" justifyContent="center" mb={3}>
        <Button
          onClick={onClickNext}
        >
          {' '}
          Next{' '}
        </Button>
      </Box>
    </Box>
  );
};

export default Welcome;
