import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import Stepper from '../components/UI/Stepper/Stepper';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { pagesActions } from '../redux/pages-slice';
import theme from '../theme/Theme';
import {
  getDesignsStyles,
  getHubspotFormEndpoint,
  sendGtagEvent,
  submitHubspotForm,
} from '../utils/analytics';
import {
  HUBSPOT_CONTACT_FIELDS,
  HUBSPOT_PROJECT_FIELDS,
} from '../consts/analytics';
import { useQueryParams } from '../hooks/useQueryParams';

const BudgetRange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const budgetRange = useSelector((state) => state.pages.budgetRange);
  const designs = useSelector((state) => state.pages.designs) || [];
  const { email } = useSelector((state) => state.pages.contactDetails);
  const { setBudget, setDesignsImages } = pagesActions;
  const { isMobile } = useSelector((state) => state.pages.global);

  const budgetRangeOptions = [
    {
      label: 'Less than $10,000',
      value: 'Less than $10K',
    },
    {
      label: '$10,000 - $50,000',
      value: '$10K-$50K',
    },
    {
      label: '$50,000 - $100,000',
      value: '$50K-$100K',
    },
    {
      label: 'Above $100,000',
      value: '$100K or above',
    },
  ];

  const onBudgetChange = (value) => {
    dispatch(setBudget(value));
  };

  const prepareGalleryPage = async () => {
    const designs = await getDesignsStyles();

    dispatch(setDesignsImages(designs));
  };

  const onClickNext = async () => {
    let budgetValue = budgetRange ? budgetRange : 'Budget Unknown';

    const properties = {
      [HUBSPOT_CONTACT_FIELDS.email]: email,
      [HUBSPOT_PROJECT_FIELDS.budget]: budgetValue,
    };

    const url = getHubspotFormEndpoint('contacts', 'create');

    const data = { pageName: 'Budget Range', properties };
    const gtagData = {
      event: 'mhBudgetSubmitted',
      budget: budgetRange,
    };

    await submitHubspotForm(url, data);
    sendGtagEvent(gtagData);
    navigate(`/styles${queryParams}`);
  };

  useEffect(() => {
    if (!designs.length) {
      prepareGalleryPage();
    }
  }, []);

  const renderBudgetList = () => (
    <>
      <Typography
        variant="h1"
        align="center"
        sx={{
          marginBottom: '25px',
          [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
          },
        }}
      >
        What is your estimated budget
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '30px',
          [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
          },

          '& .MuiSvgIcon-root': {
            width: '40px',
            height: '40px',
            marginRight: '20px',

            [theme.breakpoints.down('sm')]: {
              width: '30px',
              height: '30px',
            },
          },
        }}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            {budgetRangeOptions.map((option) => (
              <FormControlLabel
                label={option.label}
                sx={{
                  marginBottom: '20px',
                  '.MuiFormControlLabel-label': {
                    typography: 'subtitle1',
                  },
                }}
                control={
                  <Radio
                    checked={option.value === budgetRange}
                    onChange={(e) => onBudgetChange(option.value)}
                    value={option}
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'B' }}
                  />
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );

  return (
    <>
      <Box sx={{ paddingTop: '70px',

[theme.breakpoints.down('sm')]: {
           paddingTop: '30px'
          },

    }}>
        <Box sx={{ marginBottom: '30px' }}>
          <Stepper activeStep={1} />
        </Box>

        {renderBudgetList()}

        <Box display="flex" justifyContent="center">
          <Button
            sx={{
              padding: '20px 75px',
              color: 'common.white',
              borderRadius: '10px',
              marginBottom: '20px',
            }}
            onClick={onClickNext}
            disabled={!budgetRange}
          >
            Next
          </Button>
        </Box>

        <Typography
          onClick={() => {
            onClickNext();
          }}
          sx={{
            color: 'greys.mid',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          align="center"
        >
          I'm not sure yet
        </Typography>
      </Box>
    </>
  );
};

export default BudgetRange;
