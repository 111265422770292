import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import merilynRep from '../assets/get_avatar.jpg';
import sidePic from '../assets/icons/myhome-summary-side-pic.png';
import SummaryDetails from '../components/SummaryDetails/SummaryDetails';
import theme from '../theme/Theme';
import Button from '../components/UI/Button/Button';
import footerBackground from '../assets/summary-button-bg.png';

const Summary = () => {
  const allPagesData = useSelector((state) => state.pages) || [];
  const {
    budgetRange,
    contactDetails: { email, fullName, phone },
    designs,
    rooms: { rooms },
    startDate,
    welcome: { zipcode },
    summary: { notes },
  } = allPagesData;

  const summaryDetails = {
    budgetRange,
    fullName,
    email,
    phone,
    designs,
    rooms,
    startDate,
    zipCode: zipcode,
    notes,
  };

  const projectsPageRedirect = () => {
    window.open('https://www.myhomeus.com/project-gallery/', '_blank');
  };

  return (
    <>
      <Box
        sx={{
          padding: '30px 100px 0',
          marginBottom: '85px',
          [theme.breakpoints.down('sm')]: {
            padding: '0',
            marginBottom: '30px',
          },
        }}
      >
        <Typography
          variant="h1"
          align="center"
          sx={{
            marginBottom: '64px',
            [theme.breakpoints.down('sm')]: {
              marginBottom: '25px',
            },
          }}
        >
          Your estimation is on its way 🎉
        </Typography>

        <Box
          sx={{
            maxWidth: '1160px',
            margin: '0 auto 53px',
            backgroundColor: '#EAE6E2',
            borderRadius: '10px',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
              marginBottom: '30px'   
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                flex: 2,
                padding: '40px',
                marginRight: '28px',
                [theme.breakpoints.down('sm')]: {
                  padding: '30px',
                  marginRight: '0'

                },
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: '13px',
                  fontWeight: 700,
                  [theme.breakpoints.down('sm')]: {
                    marginBottom: '7px',
                  },
                }}
              >
                Thanks!
              </Typography>

              <Typography
                variant="paragraph"
                sx={{
                  display: 'block',
                  marginBottom: '25px',
                  [theme.breakpoints.down('sm')]: {
                    marginBottom: '20px',
                  },
                }}
              >
                A Renovation Consultant will contact you shortly to fill in any more details and provide you with a cost estimation for your desired renovation project.
              </Typography>

              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    marginRight: '10px',
                    '& img': {
                      borderRadius: '50%',
                      display: 'block',
                      width: '70px',
                      height: '70px'
                    },
                  }}
                >
                  <img src={merilynRep} alt="" />
                </Box>

                <Box>
                  <Typography variant="paragraph" sx={{ fontWeight: '700' }}>
                    Merilin Ajazi
                  </Typography>
                  <Typography
                    variant="paragraph"
                    sx={{ fontWeight: '400', display: 'block' }}
                  >
                    Client Relations Manager
                  </Typography>
                  <Typography
                    variant="paragraph"
                    sx={{ fontWeight: '400', display: 'block' }}
                  >
                    Office: 212.666.2888
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexBasis: '475px',
                flexGrow: 0,

                '& img': {
                  display: 'block',
                  width: '100%',
                },

                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              }}
            >
              <img src={sidePic} alt="" />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: '1160px',
            margin: '0 auto',
            padding: '0 20px',
            [theme.breakpoints.down('sm')]: {
              padding: '0',
            },
          }}
        >
          <SummaryDetails {...summaryDetails} />
        </Box>
      </Box>

      <Box
        sx={{
          height: '497px',
          textAlign: 'center',
          backgroundColor: '#444C57',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundImage: `url(${footerBackground})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          left: '-40px',
          width: 'calc(100% + 80px)'
        }}
      >
        <Typography
          variant="h1"
          textTransform={'uppercase'}
          sx={{
            lineHeight: '55px',
            color: 'white',
            marginBottom: '41px',
            [theme.breakpoints.down('sm')]: {
              marginBottom: '30px',
              fontSize: '28px',
              padding: '40px',
              lineHeight: '36px'
            },
          }}
        >
          Visit our projects gallery for inspiration
        </Typography>

        <Box>
          <Button
            text="Project Gallery"
            sx={{
              width: '100%',
              boxShadow: 'none',
              maxWidth: '300px',
              padding: '25px 15px',
              "&:hover": {
                background: "#818B7E",
                color: '#fff',
                borderColor: '#fff'
              },
              [theme.breakpoints.down('sm')]: {
                maxWidth: 'unset',
                padding: '15px',
              },
            }}
            onClick={projectsPageRedirect}
          />
        </Box>
      </Box>
    </>
  );
};

export default Summary;
