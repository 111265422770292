import React from 'react';

const OtherRoomIcon = ({ fill }) => {
  return (
    <svg
      width="73"
      height="62"
      viewBox="0 0 73 62"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M68.0093 20.6667H51.4828V16.5333H53.6074C54.7886 16.5333 55.7462 15.608 55.7462 14.4667C55.7462 13.3253 54.7886 12.4 53.6074 12.4H51.4828V8.27356C51.4828 3.7116 47.6416 0 42.9201 0C38.1987 0 34.3575 3.71146 34.3575 8.27356V10.3333C34.3575 11.4747 35.315 12.4 36.4963 12.4C37.6776 12.4 38.6351 11.4747 38.6351 10.3333V8.27356C38.6351 5.99072 40.5573 4.13333 42.92 4.13333C45.2827 4.13333 47.2049 5.99058 47.2049 8.27356V20.6667C44.734 20.6667 7.4872 20.6667 4.99052 20.6667C2.23876 20.6667 0 22.8299 0 25.4889V31C0 32.1414 0.957555 33.0667 2.13881 33.0667H4.59829L5.91999 48.3914C6.31749 53.0008 9.81978 56.749 14.4386 57.6568V59.9333C14.4386 61.0747 15.3962 62 16.5774 62C17.7587 62 18.7162 61.0747 18.7162 59.9333V57.8667H54.2906V59.9333C54.2906 61.0747 55.2482 62 56.4294 62C57.6107 62 58.5682 61.0747 58.5682 59.9333V57.6567C63.1845 56.7494 66.6891 53.0032 67.0869 48.3914L68.4088 33.0667H70.8612C72.0424 33.0667 73 32.1414 73 31V25.4889C73 22.8299 70.7612 20.6667 68.0093 20.6667ZM30.0927 41.3333H17.2608V24.8H30.0813L30.0927 41.3333ZM4.27777 25.4889C4.27777 25.109 4.59757 24.8 4.99066 24.8H12.983V28.9333H4.27777V25.4889ZM62.8241 48.0482C62.5492 51.2361 59.7404 53.7333 56.4297 53.7333H16.5776C13.2668 53.7333 10.458 51.2361 10.1831 48.0481L8.89089 33.0667H12.983V43.4C12.983 44.5414 13.9406 45.4667 15.1218 45.4667H32.2329C33.4142 45.4667 34.3726 44.5404 34.3717 43.3986L34.3646 33.0667H64.1161L62.8241 48.0482ZM68.7222 28.9333H34.3619L34.359 24.8H68.0093C68.4024 24.8 68.7222 25.109 68.7222 25.4889V28.9333Z" />
    </svg>
  );
};

export default OtherRoomIcon;
