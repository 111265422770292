import React from 'react';
import Button from '@mui/material/Button';

const AppButton = ({ disabled, text, onClick, sx = {} }) => {
  return (
    <>
      <Button
        onClick={() => (onClick ? onClick() : null)}
        disabled={disabled}
        sx={{ ...sx }}
      >
        {text ? text : 'Next'}
      </Button>
    </>
  );
};

export default AppButton;
