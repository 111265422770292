import React from 'react';
import { Box } from '@mui/system';
import Stepper from '../components/UI/Stepper/Stepper';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { pagesActions } from '../redux/pages-slice';
import theme from '../theme/Theme';
import {
  getHubspotFormEndpoint,
  sendGtagEvent,
  submitHubspotForm,
} from '../utils/analytics';
import {
  HUBSPOT_CONTACT_FIELDS,
  HUBSPOT_PROJECT_FIELDS,
} from '../consts/analytics';
import { useQueryParams } from '../hooks/useQueryParams';

const StartDate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const startDate = useSelector((state) => state.pages.startDate);
  const { email } = useSelector((state) => state.pages.contactDetails);
  const { setStartDate } = pagesActions;
  const currentStep = 0;
  const { isMobile } = useSelector((state) => state.pages.global);

  const startDateOptions = [
    'As soon as possible',
    '1-3 Months',
    '3-6 Months',
    '6+ Months',
  ];

  const nextDisabled = !startDate;

  const onStartDateChange = (e) => {
    dispatch(setStartDate(e.target.value));
  };

  const onClickNext = async () => {
    const properties = {
      [HUBSPOT_CONTACT_FIELDS.email]: email,
      [HUBSPOT_PROJECT_FIELDS.start_date]: startDate,
    };
    const gtagData = {
      event: 'mhWhenSubmitted',
      timeframe: startDate,
    };

    const url = getHubspotFormEndpoint('contacts', 'create');

    const data = { pageName: 'Start Date', properties };

    await submitHubspotForm(url, data);

    sendGtagEvent(gtagData);
    navigate(`/budget${queryParams}`);
  };

  const renderStartDateList = () => (
    <>
      <Typography
        variant="h1"
        align="center"
        sx={{
          marginBottom: '20px',
          [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
          },
        }}
      >
        When would you like to start?
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '30px',
          [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
          },

          '& .MuiSvgIcon-root': {
            width: '40px',
            height: '40px',
            marginRight: '20px',

            [theme.breakpoints.down('sm')]: {
              width: '30px',
              height: '30px',
            },
          },
        }}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            {startDateOptions.map((option) => (
              <FormControlLabel
                label={option}
                sx={{
                  marginBottom: '20px',
                  '&:last-child': {
                    marginBottom: '0',
                  },
                  '.MuiFormControlLabel-label': {
                    typography: 'subtitle1',
                  },
                }}
                control={
                  <Radio
                    checked={startDate === option}
                    onChange={(e) => onStartDateChange(e)}
                    value={option}
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'B' }}
                  />
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );

  return (
    <Box sx={{ paddingTop: '70px',


[theme.breakpoints.down('sm')]: {
           paddingTop: '30px'
          }
  }}>
      <Box sx={{ marginBottom: '30px' }}>
        <Stepper activeStep={currentStep} />
      </Box>

      <Typography
        align="center"
        variant="h2"
        sx={{
          color: 'orange.main',
          marginTop: '15px',
          fontWeight: 400,
          [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
          },
        }}
      >
        Only 3 more questions to refine the estimation
      </Typography>

      {renderStartDateList()}

      <Box display="flex" justifyContent="center">
        <Button
          sx={{
            padding: '20px 75px',
            color: 'common.white',
            borderRadius: '10px',
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default StartDate;
