import React from 'react';

const OtherRoomIcon = ({ fill, className = '' }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g filter="url(#filter0_d_171_1289)">
        <path
          d="M15 0C8.93423 0 4 4.9347 4 11C4 17.0653 8.93423 22 15 22C21.0658 22 26 17.0653 26 11C26 4.9347 21.0658 0 15 0ZM19.373 11.3854L12.9563 15.5104C12.8811 15.5592 12.7943 15.5833 12.7083 15.5833C12.6332 15.5833 12.5571 15.5645 12.489 15.5274C12.3413 15.4468 12.25 15.2928 12.25 15.125V6.875C12.25 6.70716 12.3413 6.55316 12.489 6.4726C12.6341 6.39293 12.8158 6.39783 12.9563 6.48961L19.373 10.6146C19.5037 10.6987 19.5833 10.8442 19.5833 11C19.5833 11.1558 19.5037 11.3012 19.373 11.3854Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_171_1289"
          x="0"
          y="0"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_171_1289"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_171_1289"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default OtherRoomIcon;
