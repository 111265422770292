import * as React from 'react';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import HeartIcon from '../Icons/HeartIcon';
import { useSelector } from 'react-redux';

export default function ImageMasonry({ items = [], onSelectItem = () => {} }) {
  const { isMobile } = useSelector((state) => state.pages.global);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '170px',
        '& > .MuiMasonry-root': {
          marginRight: '0',
        },
      }}
    >
      <Masonry columns={isMobile ? 2 : 5} spacing={3}>
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: '7px',
              overflow: 'hidden',
              position: 'relative',
              cursor: 'pointer',
            }}
            onClick={() => onSelectItem(index)}
          >
            <img
              src={`${item.img}?w=162&auto=format`}
              srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
              }}
            />

            <Box
              sx={{
                position: 'absolute',
                background: 'white',
                top: '10px',
                left: '10px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 5px 10px rgb(0 0 0 / 25%)',
                width: '32px',
                height: '32px',

                '& svg': {
                  width: '15px',
                  height: '15px',
                  transition: '.2s ease all',
                },
              }}
            >
              <HeartIcon fill={item.isSelected ? '#CF6F4C' : '#fff'} />
            </Box>
          </Box>
        ))}
      </Masonry>
    </Box>
  );
}
