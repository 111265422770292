import { configureStore } from '@reduxjs/toolkit';

import uiSlice from './ui-slice';
import pagesSlice from './pages-slice';

const store = configureStore({
  reducer: { pages: pagesSlice.reducer },
});

export default store;
