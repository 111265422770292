import React from 'react';

const HeartIcon = ({ fill }) => {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3029 14.3441C9.25141 14.3742 9.20461 14.4012 9.16284 14.4252C9.12082 14.4007 9.07369 14.3732 9.02181 14.3425C8.77594 14.1973 8.42345 13.9837 7.99992 13.7117C7.1519 13.167 6.02383 12.3915 4.89824 11.4655C3.77018 10.5375 2.65952 9.47048 1.83467 8.3454C1.00572 7.2147 0.5 6.07257 0.5 4.98383C0.5 2.50758 2.50739 0.500151 4.98368 0.500166L4.98758 0.500135C6.50172 0.488327 7.91728 1.2499 8.74179 2.51993L9.15872 3.16214L9.57908 2.52217C10.41 1.2571 11.8232 0.496694 13.3368 0.500148C15.8087 0.512513 17.8096 2.5132 17.8223 4.98508C17.8219 6.09319 17.315 7.24449 16.4872 8.37687C15.6627 9.50474 14.5526 10.5691 13.425 11.4922C12.2998 12.4134 11.1721 13.1819 10.3244 13.7207C9.90101 13.9898 9.54865 14.2008 9.3029 14.3441Z"
        stroke={fill === '#CF6F4C' ? '#fff' : '#C4C7CC'}
      />
    </svg>
  );
};

export default HeartIcon;
