import React, { useState, useEffect } from 'react';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { removeSpecialCharacters, validateField } from '../utils/validations';
import { FIELDS } from '../consts/fields';
import Button from '../components/UI/Button/Button';
import theme, { themeTextsMapping } from '../theme/Theme';
import { useNavigate } from 'react-router';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { pagesActions } from '../redux/pages-slice';
import {
  getSelectedRoomsString,
  getHubspotFormEndpoint,
  submitHubspotForm,
  splitFullName,
  sendHubSpotIdentiy,
} from '../utils/analytics';
import {
  HUBSPOT_CONTACT_FIELDS,
  HUBSPOT_MARKETING_FIELDS,
  HUBSPOT_OBJECT_TYPES,
  HUBSPOT_PROJECT_FIELDS,
} from '../consts/analytics';
//import { useQueryParams } from '../hooks/useQueryParams';
import useMarketingParams from '../hooks/useMarketingParams';
import UtmParamsFields from '../components/UtmParamsFields/UtmParamsFields';
import getTracking from '../components/UtmParamsFields/getTracking';

const ContactDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const queryParams = useQueryParams();
  const marketingParams = useMarketingParams();
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [errors, setErrors] = useState({});
  const { fullName, email, phone } = useSelector(
    (state) => state.pages.contactDetails
  );
  const { rooms } = useSelector((state) => state.pages.rooms);
  const { zipcode } = useSelector((state) => state.pages.welcome);
  const { setContactDetails, setContactId, setProjectId } = pagesActions;
  const { isMobile } = useSelector((state) => state.pages.global);

  const phoneLabel =
    !phoneFocused && !phone
      ? `${FIELDS.phone.label} (---) --- ----`
      : FIELDS.phone.label;

  const onInputChange = (value, fieldName) => {
    dispatch(
      setContactDetails({
        field: fieldName,
        value,
      })
    );
  };

  const onClickNext = async () => {
    try {
      const isPageValid = validatePage();
      if (!isPageValid) return;

      const selectedRooms = rooms.filter((room) => room.isSelected);
      const selectedRoomsValue = getSelectedRoomsString(selectedRooms);
      const projectName = `${fullName} - ${zipcode}`;
      const { firstName, lastName } = splitFullName(fullName);
      const url = getHubspotFormEndpoint(
        HUBSPOT_OBJECT_TYPES.contacts.name,
        'create'
      );
      const trackingParams = getTracking();
      sendHubSpotIdentiy({email})

      const properties = {
        [HUBSPOT_CONTACT_FIELDS.zip]: zipcode,
        [HUBSPOT_CONTACT_FIELDS.firstname]: firstName,
        [HUBSPOT_CONTACT_FIELDS.lastname]: lastName,
        [HUBSPOT_CONTACT_FIELDS.phone]: phone,
        [HUBSPOT_CONTACT_FIELDS.email]: email,
        [HUBSPOT_CONTACT_FIELDS.leadStatus]: 'NEW',
        [HUBSPOT_CONTACT_FIELDS.lifeCycleStage]: 'lead',
        [HUBSPOT_PROJECT_FIELDS.zipcode]: zipcode,
        [HUBSPOT_PROJECT_FIELDS.projectName]: projectName,
        [HUBSPOT_PROJECT_FIELDS.remodelingType]: selectedRoomsValue,
        [HUBSPOT_MARKETING_FIELDS.convertion_url]: 'Get Estimation',
        [HUBSPOT_MARKETING_FIELDS.utm_source]: trackingParams.source,
        [HUBSPOT_MARKETING_FIELDS.utm_campaign]: trackingParams.campaign,
        [HUBSPOT_MARKETING_FIELDS.utm_medium]: trackingParams.medium,
        [HUBSPOT_MARKETING_FIELDS.utm_term]: trackingParams.term,
        [HUBSPOT_MARKETING_FIELDS.google_analytics_click_id]: trackingParams.clientId,
        //...marketingParams,
      };

      let phoneValue = removeSpecialCharacters(phone);
      const pageName = 'Contact Details';

      const data = {
        properties,
        pageName,
      };

      await submitHubspotForm(url, data);

      navigate(`/start-date`);
    } catch (error) {
      console.log(error);
    }
  };

  const validatePage = () => {
    let isPageValid = true;
    const errorMessages = {
      fullName: '',
      email: '',
      phone: '',
    };
    const isNameValid = validateField(
      FIELDS.fullName.validationField,
      fullName
    );
    const isEmailValid = validateField(FIELDS.email.validationField, email);
    const isPhoneValid = validateField(FIELDS.phone.validationField, phone);

    if (!isNameValid) {
      isPageValid = false;
      errorMessages.fullName = 'Enter a valid name';
    }
    if (!isEmailValid) {
      isPageValid = false;
      errorMessages.email = 'Enter a valid email addresss';
    }
    if (!isPhoneValid) {
      isPageValid = false;
      errorMessages.phone = 'Please enter a valid phone number';
    }

    if (!isPageValid) {
      setErrors({
        ...errors,
        ...errorMessages,
      });
    }

    return isPageValid;
  };

  return (
    <Box
      sx={{
        paddingTop: '45px',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '15px',
        }
      }}
    >
      <Typography
        variant={themeTextsMapping.subtitle}
        align="center"
        sx={{ marginTop: '15px' }}
      >
        Before we move on...
      </Typography>

      <Typography
        variant="h1"
        align="center"
        sx={{
          marginBottom: '30px',
          [theme.breakpoints.down('sm')]: {
            marginBottom: '31px',
          },
        }}
      >
        How can we {isMobile && <br />}contact you?
      </Typography>

      <form id="getContactForm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            maxWidth: '400px',

            [theme.breakpoints.down('sm')]: {
              maxWidth: 'unset',
            },
          }}
        >
          <TextField
            id="outlined-required"
            label="Full Name"
            type="text"
            name="fullName"
            color="orange"
            sx={{ marginBottom: '30px', width: '100%' }}
            value={fullName}
            onChange={(e) => onInputChange(e.target.value, 'fullName')}
            error={!!errors?.fullName}
            helperText={errors?.fullName}
          />

          <TextField
            id="outlined-required"
            label="Email"
            name="email"
            color="orange"
            sx={{ marginBottom: '30px', width: '100%' }}
            value={email}
            onChange={(e) => onInputChange(e.target.value, 'email')}
            error={!!errors?.email}
            helperText={errors?.email}
          />

          <InputMask
            mask="(999) 999 9999"
            maskChar="-"
            value={phone}
            onFocus={() => setPhoneFocused(true)}
            onBlur={() => setPhoneFocused(false)}
            onChange={(e) => onInputChange(e.target.value, 'phone')}
          >
            {() => (
              <TextField
                id="outlined-required"
                label={phoneLabel}
                name="phone"
                color="orange"
                sx={{ marginBottom: '30px', width: '100%' }}
                error={!!errors?.phone}
                helperText={errors?.phone}
              />
            )}
          </InputMask>

          <UtmParamsFields />
        </Box>
      </form>

      <Box display="flex" justifyContent="center" sx={{ marginBottom: '30px' }}>
        <Button
          sx={{
            padding: '20px 75px',
            color: 'common.white',
            borderRadius: '10px',
          }}
          onClick={onClickNext}
          text="Next"
        />
      </Box>

      <Box
        sx={{
          maxWidth: '600px',
          margin: '0 auto',

          [theme.breakpoints.down('sm')]: {
            padding: '10px 30px 50px 30px',
          },
        }}
      >
        <Typography
          variant={themeTextsMapping.paragraph}
          sx={{ color: 'greys.dark', fontSize: '14px !important', textAlign: 'justify' }}
        >
          By clicking Continue, I agree that Myhome representative may call or text me at the
          phone number entered above, including using an autodialer, regarding
          my renovation and to give me estimates, offers and other information
          about its products and services. I understand that my consent is not
          required as a condition of any purchase.
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactDetails;
