import { createSlice } from '@reduxjs/toolkit';
import { getSelectedRoomIndex } from '../utils/rooms';

const pagesSlice = createSlice({
  name: 'pages',
  currentUserId: '',
  initialState: {
    global: {
      currentPage: 'welcome',
      isMobile: false,
      projectId: null,
      contactId: null,
      baseUrl: '',
    },
    noService: {
      fullName: '',
      email: '',
    },
    welcome: {
      zipcode: '',
    },
    rooms: {
      rooms: [
        {
          id: 1,
          name: 'Kitchen',
          type: 'Kitchen',
          isSelected: false,
          quantity: 0,
          media: [{}, {}, {}, {}, {}],
          notes: '',
        },
        {
          id: 2,
          name: 'Bathroom',
          type: 'Bathroom',
          supportsMultipleRooms: true,
          quantity: 0,
          isSelected: false,
          media: [{}, {}, {}, {}, {}],
          notes: '',
        },
        {
          id: 3,
          name: 'Other',
          type: 'Other',
          description: '(Flooring, painting, etc.)',
          isSelected: false,
          quantity: 0,
          media: [{}, {}, {}, {}, {}],
          notes: '',
        },
        {
          id: 4,
          name: 'Full Renovation',
          type: 'Full home renovation',
          allHouse: true,
          isSelected: false,
          quantity: 0,
          media: [{}, {}, {}, {}, {}],
          notes: '',
        },
      ],
      allHouseSelected: false,
    },
    contactDetails: {
      fullName: '',
      email: '',
      phone: '',
    },
    startDate: '',
    budgetRange: '',
    designs: [],
    summary: {
      notes: '',
    },
  },
  reducers: {
    setProjectNotes(state, action) {
      const notes = action.payload;

      state.summary.notes = notes;
    },
    setZipcode(state, action) {
      const zipcode = action.payload;
      state.welcome.zipcode = zipcode;
    },
    setNoServiceUserDetails(state, action) {
      const { fieldName, value } = action.payload;

      state.noService[fieldName] = value;
    },
    setContactDetails(state, action) {
      const { field, value } = action.payload;

      state.contactDetails[field] = value;
    },
    setStartDate(state, action) {
      const startDate = action.payload;

      state.startDate = startDate;
    },
    setBudget(state, action) {
      const budget = action.payload;

      state.budgetRange = budget;
    },
    setPickedStyles(state, action) {
      const itemIndex = action.payload;
      const currentStyles = [...state.designs];
      const selectedStyle = currentStyles?.[itemIndex];
      selectedStyle.isSelected = !selectedStyle.isSelected;

      state.designs = currentStyles;
    },
    toggleSelectedRoom(state, action) {
      const { room, meta = {} } = action.payload;
      const roomId = room.id;
      const updatedRooms = [...state.rooms.rooms];
      const selectedRoomIndex = getSelectedRoomIndex(roomId, state.rooms.rooms);

      const selectedRoom = updatedRooms[selectedRoomIndex];
      const isSelected = selectedRoom.isSelected;

      selectedRoom.isSelected = !isSelected;

      if (meta?.allHouse) {
        state.rooms.allHouseSelected = true;

        updatedRooms.forEach((room, index) => {
          if (index !== selectedRoomIndex) {
            room.isSelected = false;
            room.quantity = 0;
          } else {
            room.isSelected = true;
          }
        });

        state.rooms.rooms = updatedRooms.filter((room) => !room?.hidden);
        return;
      } else {
        if (state.rooms.allHouseSelected) {
          state.rooms.allHouseSelected = false;

          updatedRooms.forEach((room) => {
            if (room.allHouse) {
              room.isSelected = false;
            }
          });
        }
      }

      if (selectedRoom.supportsMultipleRooms) {
        if (selectedRoom.isSelected && selectedRoom.quantity === 0) {
          selectedRoom.quantity = 1;
        } else if (!selectedRoom.isSelected && selectedRoom.quantity > 0) {
          if (selectedRoom.quantity >= 1) {
            state.rooms.rooms = updatedRooms.filter((room) => {
              if (!room.type === selectedRoom.type) return true;

              return !room?.hidden;
            });

            selectedRoom.quantity = 0;
            return;
          }
        }
      }

      state.rooms.rooms = updatedRooms;
    },
    updateRoomQuantity(state, action) {
      const { selectedRoomIndex, quantity } = action.payload;
      const updatedRooms = [...state.rooms.rooms];
      const selectedRoom = updatedRooms[selectedRoomIndex];

      if (state.rooms.allHouseSelected) {
        state.rooms.allHouseSelected = false;
        state.rooms.rooms.forEach((room) => (room.isSelected = false));
      }

      if (quantity > 0 && !selectedRoom?.isSelected) {
        selectedRoom.isSelected = true;
      } else if (quantity === 0) {
        selectedRoom.isSelected = false;
      } else if (quantity >= 1) {
        const isAdding = quantity > selectedRoom.quantity;
        const lastId = Math.max(...state.rooms.rooms.map((room) => room.id));

        if (isAdding) {
          const selectedRoomReplicate = { ...selectedRoom };
          const newId = lastId + 1;
          selectedRoomReplicate.id = newId;
          selectedRoomReplicate.name = `${selectedRoomReplicate.name} ${quantity}`;
          selectedRoomReplicate.hidden = true;

          delete selectedRoomReplicate.quantity;

          state.rooms.rooms.splice(lastId - 2, 0, selectedRoomReplicate);
        } else {
          const roomToRemove = `${selectedRoom.name} ${selectedRoom.quantity}`;
          const roomToRemoveIndex = state.rooms.rooms.findIndex((room) => {
            return room.name === roomToRemove;
          });

          state.rooms.rooms.splice(roomToRemoveIndex, 1);
        }
      }

      selectedRoom.quantity = quantity;

      state.rooms.selectedRooms = updatedRooms;
    },
    setRoomMediaSlotLoading(state, action) {
      const { id, mediaSlotIndex, loadingState } = action.payload;
      const currentStateRooms = [...state.rooms.rooms];
      const roomIndex = currentStateRooms?.findIndex((room) => room.id === id);
      const roomClone = { ...currentStateRooms[roomIndex] };
      const roomMedia = roomClone.media || [];
      const roomMediaClone = [...roomMedia];
      const mediaSlotClone = { ...roomMediaClone[mediaSlotIndex] };

      mediaSlotClone.loading = loadingState;
      roomMediaClone[mediaSlotIndex] = mediaSlotClone;

      roomClone.media = roomMediaClone;

      currentStateRooms[roomIndex] = roomClone;

      state.rooms.rooms = currentStateRooms;
    },
    setRoomMedia(state, action) {
      const { id, uploadedFile, mediaSlotIndex } = action.payload;
      const currentStateRooms = [...state.rooms.rooms];
      const roomIndex = currentStateRooms?.findIndex((room) => room.id === id);
      const roomClone = { ...currentStateRooms[roomIndex] };
      const roomMedia = roomClone.media || [];
      const isFileExist = roomMedia.some(
        (currFile) => currFile.name === uploadedFile.name
      );

      if (isFileExist) return;

      const roomMediaClone = [...roomMedia];
      roomMediaClone[mediaSlotIndex] = uploadedFile;
      roomClone.media = roomMediaClone;

      currentStateRooms[roomIndex] = roomClone;
      state.rooms.rooms = currentStateRooms;
    },
    deleteRoomMedia(state, action) {
      const { roomId, mediaSlotIndex } = action.payload;
      const currentStateRooms = [...state.rooms.rooms];
      const roomIndex = currentStateRooms?.findIndex(
        (room) => room.id === roomId
      );
      const roomClone = { ...currentStateRooms[roomIndex] };
      const roomMediaClone = [...roomClone.media];
      roomMediaClone[mediaSlotIndex] = {};

      roomClone.media = roomMediaClone;

      currentStateRooms[roomIndex] = roomClone;
      state.rooms.rooms = currentStateRooms;
    },
    setRoomNotes(state, action) {
      const { id, value } = action.payload;
      const currentStateRoom = state.rooms.rooms;
      const room = currentStateRoom?.find((room) => room.id === id);

      room.notes = value;
    },
    setIsMobile(state, action) {
      const data = action.payload;
      state.global.isMobile = data;
    },
    setContactId(state, action) {
      const contactId = action.payload;

      state.global.contactId = contactId;
    },
    setProjectId(state, action) {
      const projectId = action.payload;

      state.global.projectId = projectId;
    },
    setDesignsImages(state, action) {
      const designsData = action.payload;

      state.designs = designsData;
    },
  },
});

export const pagesActions = pagesSlice.actions;

export default pagesSlice;
