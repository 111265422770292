import axios from 'axios';

import { VALIDATE_ZIP_URL, GET_ROOM_STYLES_URL } from '../consts/analytics';
import { BASE_URL } from '../consts/global';

export const getHubspotFormEndpoint = (formType = '', method = '') => {
  const endPoints = {
    contacts: {
      create: `${BASE_URL}/api/v1/contacts`,
    },
  };

  return endPoints[formType][method] || '';
};

export const validateZipCode = async (zipcode = '') => {
  const url = VALIDATE_ZIP_URL;

  const payload = {
    zipcode,
  };

  const res = await axios.post(url, payload);

  return res.data;
};

export const getDesignsStyles = async (zipcode = '') => {
  const url = GET_ROOM_STYLES_URL;

  const res = await axios.get(url);

  return res.data;
};

export const getHubspotToken = () =>
  document.cookie.replace(
    /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
    '$1'
  );

export const submitHubspotForm = async (url = '', data = {}) => {
  const hutk = getHubspotToken();

  let { pageName = '', properties = {}, outOfZone = false } = data;

  const payload = {
    properties,
    outOfZone,
    context: {
      hutk,
      pageName,
    },
  };

  const res = await axios.post(url, payload);

  return res.data;
};

export const getSelectedRoomsString = (selectedRooms = []) => {
  let roomsString = '';
  let selectedTypes = [];

  if (selectedRooms.length) {
    selectedTypes = [...new Set(selectedRooms.map((room) => room.type))];

    roomsString = selectedTypes.join(' + ');
  }

  if (roomsString.includes('Other')) {
    if (roomsString.includes('Bathroom') || roomsString.includes('Kitchen')) {
      roomsString = roomsString.replace('+ Other', '');
    }
  }

  return roomsString.trim();
};

export const uploadRoomImage = async (fileData = {}) => {
  try {
    // const url =
    //   'https://get-estimation-service.herokuapp.com/api/v1/rooms/image-upload';
    const url = `${BASE_URL}/api/v1/rooms/image-upload`;
    const hutk = getHubspotToken();

    const res = await axios.post(url, fileData, {
      headers: {
        hutk,
        'content-type': 'multipart/form-data',
      },
    });

    return res.data;
  } catch (error) {
    console.log(error);

    return {};
  }
};

export const deleteRoomImage = async (fileId = '') => {
  try {
    const url = `${BASE_URL}/api/v1/rooms/image/${fileId}`;
    const hutk = getHubspotToken();

    const res = await axios.delete(url, {
      headers: {
        hutk,
        'content-type': 'multipart/form-data',
      },
    });

    return res.data;
  } catch (error) {
    console.log(error);

    return {};
  }
};

export const prepareProjectNotesForHubspot = ({
  rooms = [],
  projectNotes = '',
}) => {
  let baseProjectNotes = `General Notes: ${projectNotes} `;

  let roomsString = rooms
    .map((room) => (!!room.notes ? `[${room.name}]: ${room.notes}` : ''))
    .join(' ')
    .trim();

  return (baseProjectNotes += roomsString);
};

export const sendGtagEvent = (data = {}) => {
  if (window?.dataLayer?.push) {
    window.dataLayer.push(data);
  }
};

export const splitFullName = (fullName = '') => {
  let firstName = '',
    lastName = '';

  if (fullName && typeof fullName === 'string') {
    const splittedFulName = fullName.trim().split(' ');
    firstName = splittedFulName.slice(0, -1).join(' ');
    lastName = splittedFulName.slice(-1).join(' ');
  }

  return {
    firstName,
    lastName,
  };
};

export const sendHubSpotIdentiy = (data = {}) => {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['identify', { email: data.email }]);
};
