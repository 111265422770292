import KitchenIcon from '../components/UI/Icons/KitchenIcon';
import BathIcon from '../components/UI/Icons/BathIcon';
import FullRenoIcon from '../components/UI/Icons/FullRenovationIcon';
import OtherRoomIcon from '../components/UI/Icons/OtherRoomIcon';

export const iconsDictionary = {
  Kitchen: KitchenIcon,
  Bathroom: BathIcon,
  Other: OtherRoomIcon,
  'Full home renovation': FullRenoIcon,
};
