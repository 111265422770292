import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import WelcomePage from '../pages/Welcome';
import NoService from '../pages/NoService';
import Rooms from '../pages/Rooms';
import ContactDetails from '../pages/ContactDetails';
import StartDate from '../pages/StartDate';
import BudgetRange from '../pages/BudgetRange';
import Gallery from '../pages/Gallery';
import Summary from '../pages/Summary';
import NoServiceThankYou from '../pages/NoServiceThankYou';
import { useQueryParams } from '../hooks/useQueryParams';

const AppRoutes = () => {
  const queryParams = useQueryParams();

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to={`/welcome${queryParams}`} />}
        />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/no-service" element={<NoService />} />
        <Route path="/no-service-thank-you" element={<NoServiceThankYou />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/contact-details" element={<ContactDetails />} />
        <Route path="/start-date" element={<StartDate />} />
        <Route path="/budget" element={<BudgetRange />} />
        <Route path="/styles" element={<Gallery />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="*" element={<h1>Not found </h1>} />
      </Routes>
    </>
  );
};

export default AppRoutes;
