import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../../assets/get_logo.png';
import { useQueryParams } from '../../../hooks/useQueryParams';

const Logo = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryParams = useQueryParams();

  const onLogoClick = () => {
    if (pathname === '/' || pathname === '/welcome') return;
    navigate(`/welcome${queryParams}`);
  };

  return (
    <a href="https://www.myhomeus.com/">
      <img
        style={{ cursor: 'pointer' }}
        src={logo}
        alt=""
        onClick={onLogoClick}
      />
    </a>
  );
};

export default Logo;
