import React from 'react';

const KitchenIcon = ({ fill }) => {
  return (
    <svg
      width="62"
      height="63"
      viewBox="0 0 62 63"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
    >
      <g>
        <path d="M60.1836 26.8821H57.4495C57.6512 26.3136 57.762 25.7024 57.762 25.0657V19.1319H58.3677C59.3708 19.1319 60.1841 18.3186 60.1841 17.3155C60.1841 16.3123 59.3708 15.4991 58.3677 15.4991H57.762V13.6824C57.762 10.6777 55.3173 8.23321 52.3127 8.23321H49.2249V7.62762C49.2249 6.62448 48.4115 5.81122 47.4084 5.81122C46.4052 5.81122 45.592 6.62448 45.592 7.62762V8.23321H42.5042C39.4993 8.23321 37.0549 10.6777 37.0549 13.6824V15.4991H36.4492C35.4461 15.4991 34.6328 16.3123 34.6328 17.3155C34.6328 18.3186 35.4461 19.1319 36.4492 19.1319H37.0549V25.0657C37.0549 25.7024 37.1657 26.3136 37.3674 26.8821H1.81641C0.813266 26.8821 0 27.6954 0 28.6985V60.3046C0 61.3078 0.813266 62.121 1.81641 62.121H60.1836C61.1866 62.121 62 61.3078 62 60.3046V28.6985C62 27.6954 61.1866 26.8821 60.1836 26.8821ZM40.6876 13.6824C40.6876 12.6809 41.5025 11.866 42.504 11.866H52.3126C53.3142 11.866 54.129 12.6809 54.129 13.6824V15.4991H40.6876V13.6824ZM40.6876 25.0657V19.1319H54.129V25.0657C54.129 26.0673 53.3142 26.8821 52.3126 26.8821H42.504C41.5025 26.8821 40.6876 26.0673 40.6876 25.0657ZM58.3672 30.5149V34.1481H3.63281V30.5149H58.3672ZM32.8167 37.7809V58.4882H20.0411V37.7809H32.8167ZM3.63281 37.7809H16.4083V58.4882H3.63281V37.7809ZM36.4495 58.4882V37.7809H58.3672V58.4882H36.4495Z" />
        <path d="M52.9181 41.4139H41.8989C40.8958 41.4139 40.0825 42.2271 40.0825 43.2303V53.0391C40.0825 54.0423 40.8958 54.8555 41.8989 54.8555H52.9181C53.9211 54.8555 54.7345 54.0423 54.7345 53.0391V43.2303C54.7345 42.2271 53.9211 41.4139 52.9181 41.4139ZM51.1017 51.2227H43.7153V45.0467H51.1017V51.2227Z" />
        <path d="M10.9585 45.1071C9.95536 45.1071 9.14209 45.9204 9.14209 46.9235V49.3455C9.14209 50.3487 9.95536 51.1619 10.9585 51.1619C11.9616 51.1619 12.7749 50.3487 12.7749 49.3455V46.9235C12.7749 45.9204 11.9616 45.1071 10.9585 45.1071Z" />
        <path d="M25.4911 51.1619C26.4942 51.1619 27.3075 50.3487 27.3075 49.3455V46.9235C27.3075 45.9204 26.4942 45.1071 25.4911 45.1071C24.4879 45.1071 23.6747 45.9204 23.6747 46.9235V49.3455C23.6747 50.3487 24.4879 51.1619 25.4911 51.1619Z" />
        <path d="M1.81641 3.63281H9.14258V9.43478C7.0284 10.1846 5.50977 12.2036 5.50977 14.5716C5.50977 17.5763 7.95416 20.0208 10.959 20.0208C13.9638 20.0208 16.4082 17.5763 16.4082 14.5716C16.4082 12.2036 14.8896 10.1846 12.7754 9.43478V3.63281H23.6743V9.43478C21.5601 10.1846 20.0415 12.2036 20.0415 14.5716C20.0415 17.5763 22.4859 20.0208 25.4907 20.0208C28.4955 20.0208 30.9399 17.5763 30.9399 14.5716C30.9399 12.2036 29.4213 10.1846 27.3071 9.43478V3.63281H34.6332C35.6363 3.63281 36.4496 2.81955 36.4496 1.81641C36.4496 0.813266 35.6363 0 34.6332 0H1.81641C0.813266 0 0 0.813266 0 1.81641C0 2.81955 0.813145 3.63281 1.81641 3.63281ZM10.9589 16.388C9.9573 16.388 9.14246 15.5731 9.14246 14.5716C9.14246 13.57 9.9573 12.7552 10.9589 12.7552C11.9604 12.7552 12.7753 13.57 12.7753 14.5716C12.7753 15.5731 11.9606 16.388 10.9589 16.388ZM25.4906 16.388C24.489 16.388 23.6742 15.5731 23.6742 14.5716C23.6742 13.57 24.489 12.7552 25.4906 12.7552C26.4922 12.7552 27.307 13.57 27.307 14.5716C27.307 15.5731 26.4923 16.388 25.4906 16.388Z" />
      </g>
    </svg>
  );
};

export default KitchenIcon;
