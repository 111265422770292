import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const BackButton = ({ styling = {} }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        ...styling,
        '& svg': {
          display: 'block',
          width: '30px',
        },
      }}
      onClick={() => navigate(-1)}
    >
      <ArrowBackIosNewIcon />
    </Box>
  );
};

export default BackButton;
