export const FIELDS = {
  zipCode: {
    label: 'Zip-Code',
    name: 'Zip Code',
    validationField: 'zipcode',
  },
  email: {
    label: 'Email',
    name: 'Email',
    validationField: 'email',
  },
  fullName: {
    label: 'Full Name',
    name: 'Full Name',
    validationField: 'fullname',
  },
  phone: {
    label: 'Phone number',
    name: 'phone',
    validationField: 'phone',
  },
};
