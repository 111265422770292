import { createTheme } from '@mui/material/styles';

export const themeTextsMapping = {
  h1: 'h1',
  h2: 'h2',
  subtitle: 'subtitle1',
  button: 'button',
  paragraph: 'body1',
  description: 'body2',
};

const fontSizes = {
  sm: '1rem', // 16px
  md: '1.125rem', // 18px
  lg: '1.3rem', // 24px
  xl: '3rem', // 36px
};

const white = '#FFF';
const black = '#000';
const darkBlue = '#152233';
const orange = '#CF6F4C';
const trans = 'transparent'
const lightGrey = '#DAD8D2';
const disabledGrey = '#818B7E';
const orangeShadow = '0px 20px 20px rgba(250, 157, 37, 0.3);';
const darkShadow = '0px 25px 50px rgba(0, 0, 0, 0.3);';

const greyDark = '#CF6F4C';
const greyMid = '#C4C7CC';
const greyLight = '#DAD8D2';

export default createTheme({
  palette: {
    greys: {
      1: '#E7E9EB',
      2: '#DADADA',
      3: '#8A9099',
      dark: greyDark,
      mid: greyMid,
      light: greyLight,
    },
    orange: { main: orange },
    darkBlue: { main: darkBlue },
  },
  typography: {
    fontFamily: 'Roobert, Arial, sans-serif',
    [themeTextsMapping.h1]: {
      fontSize: fontSizes.xl,
      fontWeight: 500,
      marginTop: '30px',
      marginBottom: '30px',
      '@media (max-width:600px)': {
        fontSize: '36px',
      },
    },
    [themeTextsMapping.h2]: {
      fontSize: fontSizes.lg,
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '20px',
      },
    },
    [themeTextsMapping.subtitle]: {
      fontSize: fontSizes.lg,
      fontWeight: 300,
      lineHeight: '26px',
      '@media (max-width:600px)': {
        fontSize: '20px',
      },
    },
    [themeTextsMapping.paragraph]: {
      fontSize: fontSizes.md,
      fontWeight: 300,
      color: darkBlue,
      '@media (max-width:600px)': {
        fontSize: fontSizes.sm,
      },
    },
    [themeTextsMapping.description]: {
      fontSize: fontSizes.sm,
      fontWeight: 400,
    },
    button: {
      fontSize: fontSizes.md,
      color: darkBlue,
      letterSpacing: '0.1em',
      fontWeight: 400,
      '@media (max-width:600px)': {
        fontSize: fontSizes.sm,
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: orange,
          padding: '10px 75px',
          color: white,
          maxWidth: '200px',
          maxHeight: '60px',
          borderRadius: '0px',
          '&.Mui-disabled': {
            '&&': {
              background: lightGrey,
              color: disabledGrey,
              boxShadow: 'none',
            },
          },
          '&:hover': {
            backgroundColor: trans,
            border: 'solid ' + orange + ' 1px',
            color: orange
          },

          '@media (max-width:600px)': {
            maxWidth: '160px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-input': {
            padding: '15px 15px 15px 15px',
            color: darkBlue,
            fontWeight: 400,
            fontSize: '1.4em',
            border: '1px solid',
            borderColor: '#EAE6E2',
            borderRadius: '20px',
            backgroundColor: '#fff'
          },
          '& fieldset': {
            color: '#EAE6E2',
            fontWeight: '700',
            border: '0'
          },
          '&.Mui-error': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              border: '2px solid #FF0000',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(15px, 22px) scale(1)',
          fontWeight: 300,
          color: '#C4C8CC',
          '&.Mui-focused, &.MuiFormLabel-filled': {
            '&&': {
              transform: 'translate(15px, -10px) scale(0.75)',
              fontSize: fontSizes.sm,
              fontWeight: 700,
            },
            '&.Mui-error': {
              color: '#FF0000',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: fontSizes.sm,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          width: '37.5px',
          height: '37.5px',

          '&.Mui-checked': {
            backgroundColor: orange,
            borderRadius: '10px',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          width: '40px',
          height: '40px',

          '&.Mui-checked': {
            color: orange,
          },
        },
      },
    },
  },
});
