import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { TextField, Typography } from '@mui/material';
import Button from '../components/UI/Button/Button';
import { validateField } from '../utils/validations';
import { FIELDS } from '../consts/fields';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pagesActions } from '../redux/pages-slice';
import theme from '../theme/Theme';
import {
  HUBSPOT_CONTACT_FIELDS,
  HUBSPOT_MARKETING_FIELDS,
  HUBSPOT_PROJECT_FIELDS,
} from '../consts/analytics';
import {
  getHubspotFormEndpoint,
  splitFullName,
  submitHubspotForm,
} from '../utils/analytics';
import useMarketingParams from '../hooks/useMarketingParams';
//import { useQueryParams } from '../hooks/useQueryParams';
import UtmParamsFields from '../components/UtmParamsFields/UtmParamsFields';
import getTracking from '../components/UtmParamsFields/getTracking';
import merliynRep from '../assets/get_avatar.jpg';

const NoService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const marketingParams = useMarketingParams();
  //const queryParams = useQueryParams();
  const { zipcode } = useSelector((state) => state.pages.welcome);
  const { fullName, email } = useSelector((state) => state.pages.noService);
  const { isMobile } = useSelector((state) => state.pages.global);

  const [errors, setErrors] = useState({});

  const { setNoServiceUserDetails } = pagesActions;

  const onInputChange = (value, fieldName) => {
    dispatch(
      setNoServiceUserDetails({
        fieldName,
        value,
      })
    );
  };

  const onClickNext = async () => {
    validatePageFields();

    await onSubmitNoServicePage();
  };

  const validatePageFields = () => {
    let isPageValid = true;
    const errorMessages = {
      fullName: '',
      email: '',
    };
    const isNameValid = validateField(
      FIELDS.fullName.validationField,
      fullName
    );
    const isEmailValid = validateField(FIELDS.email.validationField, email);

    if (!isNameValid) {
      isPageValid = false;
      errorMessages.fullName = 'Enter a valid name';
    }

    if (!isEmailValid) {
      isPageValid = false;

      errorMessages.email = 'Enter a valid email';
    }

    if (!isPageValid) {
      setErrors({
        ...errors,
        ...errorMessages,
      });
    }

    return isPageValid;
  };

  const onSubmitNoServicePage = async () => {
    try {
      const isPageValid = validatePageFields();
      if (!isPageValid) return;
      const trackingParams = getTracking();
      const outOfZone = true;
      const { firstName, lastName } = splitFullName(fullName);
      const projectName = `${fullName} - ${zipcode}`;
      const properties = {
        [HUBSPOT_CONTACT_FIELDS.firstname]: firstName,
        [HUBSPOT_CONTACT_FIELDS.lastname]: lastName,
        [HUBSPOT_CONTACT_FIELDS.email]: email,
        [HUBSPOT_CONTACT_FIELDS.zip]: zipcode,
        [HUBSPOT_PROJECT_FIELDS.projectName]: projectName,
        [HUBSPOT_MARKETING_FIELDS.convertion_url]: 'Get Estimation',
        [HUBSPOT_MARKETING_FIELDS.utm_source]: trackingParams.source,
        [HUBSPOT_MARKETING_FIELDS.utm_campaign]: trackingParams.campaign,
        [HUBSPOT_MARKETING_FIELDS.utm_medium]: trackingParams.medium,
        [HUBSPOT_MARKETING_FIELDS.utm_term]: trackingParams.term,
        [HUBSPOT_MARKETING_FIELDS.google_analytics_click_id]: trackingParams.clientId,
        //...marketingParams,
      };
      const pageName = 'No Service';
      const url = getHubspotFormEndpoint('contacts', 'create');

      const data = { pageName, properties, outOfZone };

      await submitHubspotForm(url, data);

      navigate(`/no-service-thank-you`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        paddingTop: '50px',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '20px',
        },
      }}
    >
    {isMobile && (
        <Box
          sx={{
            marginRight: '0',
            marginBottom: '20px',

            '& img': {
              borderRadius: '50%',
              margin: '0 auto',
              display: 'block',
              width: '80px'
            },
          }}
        >
          <img src={merliynRep} alt="" />
        </Box>
      )}

      <Typography
        variant="subtitle1"
        align="center"
        color="#CF6F4C"
        sx={{
          marginTop: '15px',
        }}
      >
        Apologies! We don’t operate {isMobile && <br />}in your area.
      </Typography>

      <Typography
        variant="h1"
        align="center"
      >
        But, you’re welcome to join us
      </Typography>

      <Typography
        variant="subtitle1"
        align="center"
        sx={{ marginBottom: '40px', marginTop: '20px' }}
      >
        We’d love to send you free design and remodeling tips to inspire your renovation.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
          maxWidth: '400px',
          marginBottom: '30px',
          [theme.breakpoints.down('sm')]: {
            maxWidth: '320px',
            marginBottom: '30px',
          },
        }}
      >
        <TextField
          id="outlined-required"
          label="Full Name"
          type="text"
          color="orange"
          sx={{
            marginBottom: '20px',
            width: '100%',
          }}
          value={fullName}
          error={!!errors?.fullName}
          helperText={errors?.fullName}
          onChange={(e) =>
            onInputChange(
              e.target.value,
              'fullName',
              FIELDS.fullName.validationField
            )
          }
        />

        <TextField
          id="outlined-required"
          label="Email"
          color="orange"
          sx={{
            width: '100%',
          }}
          value={email}
          error={!!errors?.email}
          helperText={errors?.email}
          onChange={(e) =>
            onInputChange(e.target.value, 'email', FIELDS.email.validationField)
          }
        />

        <UtmParamsFields />
      </Box>

      <Box display="flex" justifyContent="center" mb={3}>
        <Button onClick={onClickNext}> Next </Button>
      </Box>
    </Box>
  );
};

export default NoService;
