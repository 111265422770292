import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import CloudIcon from '../Icons/CloudIcon';
import PlayIcon from '../Icons/PlayIcon';
import theme from '../../../theme/Theme';
import Spinner from '../Spinner/Spinner';

const UploadInputBox = ({
  onChange = () => {},
  onDelete = () => {},
  data = {},
}) => {
  const fileMimeType = data?.type || '';
  const isFileTypeImage = fileMimeType === 'IMG';
  const isFileTypeVideo = fileMimeType === 'MOVIE';
  const fileId = data?.id || '';
  const preview = data?.url || '';
  const { loading } = data;

  const inputRef = useRef(null);

  const onInputBoxClick = () => {
    if (fileId) return;

    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  const onInputChangeHandler = (e) => {
    const uploadedFile = e.target?.files?.[0];

    if (!uploadedFile) return;

    onChange({ uploadedFile });
  };

  const onDeleteHandler = (e) => {
    e.stopPropagation();
    onDelete({ fileId });
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const getFileMimeType = (file) => {
    return file?.type;
  };

  return (
    <Box
      sx={{
        border: preview ? 'none' : '2px dashed #E8E9EB',
        display: 'flex',
        width: '75px',
        height: '75px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: fileId ? 'initial' : 'pointer',
        borderRadius: '10px',
        position: 'relative',

        '& svg:not(.play-icon)': {
          width: '25px',
          height: '25px',
        },

        '& img, & video': {
          width: '100%',
          height: '100%',
          display: 'block',
          objectFit: 'cover',
        },

        '& .play-icon': {
          zIndex: 30,
          width: '30px',
          height: '30px',
          objectFit: 'unset',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        },

        [theme.breakpoints.down('sm')]: {
          width: '50px',
          height: '50px',
        },
      }}
      onClick={onInputBoxClick}
    >
      <Box
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <input
          type="file"
          name="media-input"
          hidden
          ref={inputRef}
          onChange={onInputChangeHandler}
        />

        {isFileTypeImage && preview && (
          <img src={preview} alt="Uploaded media preview" />
        )}

        {isFileTypeVideo && preview && (
          <video type={fileMimeType} poster={preview}>
            {' '}
          </video>
        )}

        {isFileTypeVideo && (
          <Box>
            <PlayIcon className="play-icon" />
          </Box>
        )}

        {!loading && !preview && <CloudIcon fill="#C4C7CC" />}

        {loading && (
          <Box sx={{ display: 'flex' }}>
            <Spinner />
          </Box>
        )}
      </Box>

      {preview && (
        <Box
          sx={{
            color: 'white',
            position: 'absolute',
            borderRadius: '50%',
            padding: '0.5em',
            width: '22px',
            height: '22px',
            border: '1px solid red',
            backgroundColor: 'red',
            top: '-10px',
            right: '-5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            fontSize: '14px',
            cursor: 'pointer',
          }}
          onClick={onDeleteHandler}
        >
          X
        </Box>
      )}
    </Box>
  );
};

export default UploadInputBox;
