import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import plusIcon from '../../../assets/icons/plus.svg';
import minusIcon from '../../../assets/icons/minus.svg';
import Image from '../../Image/Image';
import './CounterButton.css';
import theme from '../../../theme/Theme';

const disableSelectStyling = {
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
};

const CounterButton = ({ onCounterChange, max, value }) => {
  const [touched, setIsTouched] = useState(false);

  const onCounterClick = (action) => {
    if (!touched) setIsTouched(true);

    if (action === 'add') {
      if (value === max) return;

      onCounterChange(value + 1);
    } else if (action === 'subtract') {
      if (value === 0) return;
      onCounterChange(value - 1);
    }
  };

  return (
    <Box
      variant="contained"
      aria-label="outlined primary button group"
      className="counter-button"
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        background: '#DAD8D2',
        borderRadius: '10px',
        position: 'relative',
        width: '101px',
        height: '46px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          width: '85px',
          height: '40px',
        },

        '& span': {
          typography: 'h2',
        },
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          ...disableSelectStyling,

          '& img': {
            width: '11px',
          },
        }}
        component="span"
        onClick={() => onCounterClick('subtract')}
      >
        <Image src={minusIcon} />
      </Box>

      <Box
        component="span"
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          [theme.breakpoints.down('sm')]: {
            '&&': {
              fontSize: '20px',
            },
          },
        }}
      >
        {value}
      </Box>

      <Box
        component="span"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          cursor: 'pointer',
          '& img': {
            width: '11px',
          },
          ...disableSelectStyling,
        }}
        onClick={() => onCounterClick('add')}
      >
        <Image src={plusIcon} />
      </Box>
    </Box>
  );
};

export default CounterButton;
