import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { pagesActions } from './redux/pages-slice';
import { usePageViewGtag } from './hooks/usePageViewGtag';
import theme from './theme/Theme';
import Layout from './components/Layout/Layout';
import AppRoutes from './routes/Routes';
import ErrorBoundary from './components/Layout/ErrorBoundary/ErrorBoundary';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { pathname } = location;
  const {
    global: { currentPage },
  } = useSelector((state) => state.pages);
  const { setIsMobile } = pagesActions;
  const redirectUrl = `/welcome${location.search}`;

  usePageViewGtag();

  useEffect(() => {
    if (pathname !== '/' && currentPage !== `/${currentPage}`) {
      navigate(redirectUrl);
    }
  }, []);

  useEffect(() => {
    dispatch(setIsMobile(isMobile));
  }, []);

  return (
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Layout>
            <AppRoutes />
          </Layout>
        </ErrorBoundary>
      </ThemeProvider>
    </CssBaseline>
  );
}

export default App;
