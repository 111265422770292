import * as React from 'react';
import Box from '@mui/material/Box';
import theme from '../../../theme/Theme';

const steps = [
  'Select master blaster campaign settings',
  'Create an ad group',
  'Create an ad',
];

export default function Stepper({ activeStep = 0 }) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '8px',
        position: 'relative',
        backgroundColor: 'greys.2',
        width: '100%',
        maxWidth: '312px',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
          height: '5px',
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          backgroundColor: activeStep > 0 ? 'orange.main' : 'greys.2',
          position: 'relative',
        }}
      ></Box>

      <Box
        sx={{
          flex: 1,
          backgroundColor: activeStep > 1 ? 'orange.main' : 'greys.2',
          position: 'relative',
        }}
      ></Box>

      <Box
        sx={{
          backgroundColor: 'orange.main',
          transition: '.2s ease all',
          width: '25px',
          height: '25px',
          borderRadius: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: 0,

          [theme.breakpoints.down('sm')]: {
            width: '20px',
            height: '20px',
          },
        }}
      ></Box>

      <Box
        sx={{
          backgroundColor: activeStep > 0 ? 'orange.main' : 'greys.2',
          transition: '.2s ease all',
          width: '25px',
          height: '25px',
          borderRadius: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '0',
          right: '0',
          margin: 'auto',
          [theme.breakpoints.down('sm')]: {
            width: '20px',
            height: '20px',
          },
        }}
      ></Box>

      <Box
        sx={{
          backgroundColor: activeStep > 1 ? 'orange.main' : 'greys.2',
          transition: '.2s ease all',
          width: '25px',
          height: '25px',
          borderRadius: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: 0,
          [theme.breakpoints.down('sm')]: {
            width: '20px',
            height: '20px',
          },
        }}
      ></Box>
    </Box>
  );
}
