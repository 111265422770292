import { BASE_URL } from './global';

export const HUBSPOT_PORTALID_TEST = '25874835';
export const HUBSPOT_CONTACT_FORM_TEST = '876d55ea-c012-4bc9-aa28-6ef423bdedef';
export const HUBSPOT_AFTER_CONTACT_FORM =
  '5d9ba0bb-070c-4c6f-b4a0-2fd925ceb5da';
export const HUBSPOT_OUT_OF_ZONE_FORM = '902a9c2b-90e0-4823-8d63-a83869656a0e';

export const HUBSPOT_PORTALID = '21082901';

export const HUBSPOT_MARKETING_FIELDS = {
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_campaign: 'utm_campaign',
  utm_term: 'utm_term',
  google_analytics_click_id: 'gclid',
  convertion_url: 'convertion_url',
};

export const HUBSPOT_CONTACT_FIELDS = {
  email: 'email',
  zip: 'zip',
  firstname: 'firstname',
  lastname: 'lastname',
  phone: 'phone',
  lifeCycleStage: 'lifecyclestage',
  leadStatus: 'hs_lead_status',
};

export const HUBSPOT_PROJECT_FIELDS = {
  zipcode: 'postal_code',
  remodelingType: 'remodeling_type',
  projectName: 'name',
  start_date: 'desired_start_date',
  budget: 'budget',
  preferredDesignStyle: 'preferred_design_style',
  preferredDesignStyleOther: 'preferred_design_style_other',
  notes: 'project_description',
  projectFiles: 'client_photos',
};

export const HUBSPOT_OBJECT_TYPES = {
  contacts: { name: 'contacts', objectTypeId: '0-1' },
};

export const GET_ROOM_STYLES_URL = `${BASE_URL}/api/v1/rooms-styles`;
export const VALIDATE_ZIP_URL = `${BASE_URL}/api/v1/zipcodes/validate`;
