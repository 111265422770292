import React from 'react';
import { Box } from '@mui/system';
import Logo from '../../UI/Logo';
import merliynRep from '../../../assets/get_avatar.jpg';
import { useLocation } from 'react-router-dom';
import theme from '../../../theme/Theme';
import BackButton from '../BackButton/BackButton';

const TopbarStyling = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '15px 20px',
  borderBottom: '1px solid lightgrey',
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    padding: '12px',
  },
};

const merilynStyling = {
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, 50%)',
  top: '-2px',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    display: 'none'
  },

  '& img': {
    borderRadius: '50%',
    width: '80px'
  },
};

const TopBar = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Box sx={TopbarStyling}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
          },
        }}
      >
        {pathname !== '/welcome' && pathname !== '/no-service-thank-you' && (
          <BackButton
            styling={{
              padding: '10px',
              borderRight: '1px solid #DAD8D2',
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              display: 'flex',
              alignItems: 'center',

              [theme.breakpoints.up('sm')]: {
                display: 'none',
              },
            }}
          />
        )}

        <Box
          sx={{
            '& img': {
              maxWidth: '113px',
            },
          }}
        >
          <Logo />
        </Box>
      </Box>

      {pathname !== '/summary' && (
        <Box sx={merilynStyling}>
          <img src={merliynRep} alt="" />
        </Box>
      )}
    </Box>
  );
};

export default TopBar;
