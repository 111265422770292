import React from 'react';

const CloudIcon = ({ fill }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.25">
        <path d="M17.5879 14.4734L14.5774 11.4382C14.023 10.8792 13.2852 10.5713 12.5 10.5713C11.7148 10.5713 10.977 10.8792 10.4225 11.4382L7.41209 14.4734C7.03231 14.8564 7.03485 15.4747 7.41776 15.8545C7.80072 16.2343 8.41903 16.2318 8.79881 15.8488L11.4746 13.151V22.6318C11.4746 23.1712 11.9118 23.6084 12.4512 23.6084C12.9905 23.6084 13.4277 23.1712 13.4277 22.6318V13.0525L16.2012 15.8488C16.3921 16.0413 16.6432 16.1377 16.8946 16.1377C17.1431 16.1377 17.3918 16.0434 17.5822 15.8545C17.9651 15.4747 17.9677 14.8564 17.5879 14.4734Z" />
        <path d="M22.5586 8.69853V8.04795C22.5586 4.37764 19.5726 1.3916 15.9022 1.3916C14.304 1.3916 12.7586 1.9666 11.5509 3.01074C10.752 3.70146 10.1337 4.56743 9.73975 5.52983C9.13418 5.22036 8.45786 5.05371 7.76367 5.05371C5.36743 5.05371 3.41797 7.00317 3.41797 9.39941V10.2875C1.46377 10.7257 0 12.46 0 14.5264C0 15.6872 0.439404 16.7757 1.2373 17.5915C2.04497 18.4173 3.13154 18.8721 4.29688 18.8721H4.63867C5.17803 18.8721 5.61523 18.4349 5.61523 17.8955C5.61523 17.3562 5.17803 16.9189 4.63867 16.9189H4.29688C2.98262 16.9189 1.95312 15.868 1.95312 14.5264C1.95312 13.2071 3.04497 12.1338 4.38701 12.1338H4.39453C4.93389 12.1338 5.37109 11.6966 5.37109 11.1572V9.39941C5.37109 8.08013 6.44438 7.00684 7.76367 7.00684C8.44902 7.00684 9.10288 7.30234 9.55767 7.81758C9.80835 8.10156 10.2006 8.21494 10.5641 8.10855C10.9276 8.00215 11.1968 7.69517 11.2548 7.32085C11.6061 5.0541 13.604 3.34473 15.9022 3.34473C18.4956 3.34473 20.6055 5.45459 20.6055 8.04795V9.24902C20.6055 9.61035 20.805 9.94219 21.1242 10.1116C22.3102 10.7411 23.0469 11.9649 23.0469 13.3057C23.0469 15.0029 21.8934 16.4516 20.2417 16.8284C19.7159 16.9484 19.3869 17.472 19.5069 17.9978C19.6269 18.5236 20.1504 18.8527 20.6762 18.7326C21.8858 18.4566 22.9833 17.7703 23.7665 16.8002C24.5619 15.8151 25 14.574 25 13.3057C25 11.4437 24.0781 9.72837 22.5586 8.69853Z" />
      </g>
    </svg>
  );
};

export default CloudIcon;
