import React from 'react';

const TickIcon = () => {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4435 3.28699L8.42018 13.4362C7.67794 14.1879 6.47392 14.1879 5.73098 13.4362L0.556888 8.19635C-0.185629 7.4446 -0.185629 6.22522 0.556888 5.47332C1.29955 4.72128 2.50348 4.72128 3.24581 5.47304L7.07598 9.35164L15.7541 0.563816C16.4968 -0.188224 17.7008 -0.187654 18.4432 0.563816C19.1856 1.31571 19.1856 2.53466 18.4435 3.28699Z"
        fill="white"
      />
    </svg>
  );
};

export default TickIcon;
