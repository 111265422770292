import { FIELDS } from '../consts/fields';

const ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const NAME_REGEX = /^[a-z ,.'-]+$/i;
const PHONE_REGEX = /^\([0-9]{3}\) [0-9]{3} [0-9]{4}$/;

const validateZipCode = (zipCode) => {
  return new RegExp(ZIPCODE_REGEX).test(zipCode);
};

const validateEmail = (email) => {
  return new RegExp(EMAIL_REGEX).test(email);
};

const validateName = (name) => {
  return new RegExp(NAME_REGEX).test(name);
};

const validatePhone = (phone) => {
  return new RegExp(PHONE_REGEX).test(phone);
};

export const removeSpecialCharacters = (string) => {
  return string.replace(/[^A-Z0-9]/gi, '');
};

const fieldsValidations = {
  [FIELDS.zipCode.validationField]: [validateZipCode],
  [FIELDS.email.validationField]: [validateEmail],
  [FIELDS.fullName.validationField]: [validateName],
  [FIELDS.phone.validationField]: [validatePhone],
};

export const validateField = (fieldValidationName, value) => {
  const isValid = fieldsValidations[fieldValidationName]?.reduce(
    (result, validator) =>
      typeof validator === 'function' && result && validator(value),
    true
  );

  return isValid;
};
