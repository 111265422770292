import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { Divider, Typography, useMediaQuery } from '@mui/material';
import RoomTypeCard from '../components/RoomTypeCard/RoomTypeCard';
import Button from '../components/UI/Button/Button';
import theme from '../theme/Theme';
import { getSelectedRoomIndex } from '../utils/rooms';
import { useNavigate } from 'react-router';
import { pagesActions } from '../redux/pages-slice';
import { sendGtagEvent } from '../utils/analytics';
import { useQueryParams } from '../hooks/useQueryParams';

const Rooms = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { rooms } = useSelector((state) => state?.pages?.rooms);
  const { toggleSelectedRoom, updateRoomQuantity } = pagesActions;

  const selectedRooms = rooms.filter((room) => room.isSelected);
  const anyRoomsSelected = selectedRooms.length > 0;
  const selectedRoomsNames = selectedRooms.map((room) => room.name);

  const selectedRoomsNamesString = selectedRoomsNames?.length
    ? JSON.stringify(selectedRoomsNames)
    : '';

  const onSelectRoomType = (room, meta = {}) => {
    dispatch(toggleSelectedRoom({ room, meta }));
  };

  const onRoomQuantityChange = (room, quantity) => {
    const roomId = room.id;
    const selectedRoomIndex = getSelectedRoomIndex(roomId, rooms);

    dispatch(updateRoomQuantity({ selectedRoomIndex, quantity }));
  };

  return (
    <Box
    >
      <Typography
        variant="h1"
        align="center"
        sx={{
          paddingTop: '50px',
          marginBottom: '35px',
          [theme.breakpoints.down('sm')]: {
            marginBottom: '5px',
            padding: '0 10px',
          },
        }}
      >
        Which room(s) would you like to renovate?
      </Typography>

      <Typography
        variant="body2"
        align="center"
        sx={{
          color: 'orange.main',
          display: 'none',

          [theme.breakpoints.down('sm')]: {
            display: 'block',
            margin: '20px 0',
          },
        }}
      >
        You may select more than one
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'center',
          maxWidth: '1080px',
          margin: '0 auto',
          marginBottom: '30px',

          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: '0',
            marginBottom: '30px',
          },
        }}
      >
        {rooms.map((roomType, index) => (
          <>
            {roomType.name === 'Full Renovation' && (
              <Box
                key={'index-divider'}
                sx={{
                  marginRight: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Divider
                  sx={{
                    borderRightWidth: '3px',
                    maxHeight: '120px',

                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                      maxWidth: '110px',
                      margin: '0 auto',
                      borderRightWidth: '0',
                      borderTopWidth: '3px',
                      marginBottom: '20px',
                    },
                  }}
                  orientation={isMobile ? 'horizental' : 'vertical'}
                ></Divider>
              </Box>
            )}

            {!roomType?.hidden && (
              <RoomTypeCard
                key={roomType.id}
                roomType={roomType}
                isSelected={roomType.isSelected}
                onSelectCard={(roomType, meta) =>
                  onSelectRoomType(roomType, meta)
                }
                onRoomQuantityChange={(roomType, quantity) =>
                  onRoomQuantityChange(roomType, quantity)
                }
              />
            )}
          </>
        ))}
      </Box>

      <Box display="flex" justifyContent="center" mb={3}>
        <Button
          sx={{
            padding: '20px 75px',
            color: 'common.white',
            borderRadius: '10px',
          }}
          disabled={!anyRoomsSelected}
          onClick={() => {
            const gtagData = {
              event: 'mhRoomsSubmitted',
              rooms: selectedRoomsNamesString,
            };

            sendGtagEvent(gtagData);

            navigate(`/contact-details${queryParams}`);
          }}
        >
          {' '}
          Next{' '}
        </Button>
      </Box>
    </Box>
  );
};

export default Rooms;
