import React, { memo, useRef } from 'react';
import { Box } from '@mui/system';
import Radio from '@mui/material/Radio';
import { Checkbox, Typography, useMediaQuery } from '@mui/material';
import { iconsDictionary } from '../../utils/icons';
import CounterButton from '../UI/CounterButton/CounterButton';
import TickIcon from '../UI/Icons/TickIcon';
import theme from '../../theme/Theme';

const RoomTypeCard = ({
  roomType,
  isSelected,
  onSelectCard,
  onRoomQuantityChange,
  isEditMode,
}) => {
  const {
    id: roomId,
    name,
    type,
    description,
    supportsMultipleRooms,
    allHouse,
  } = roomType;

  const isMobile = useMediaQuery('(max-width:600px)');
  const cardWrapperRef = useRef(null);
  const RoomIcon = iconsDictionary?.[type];

  const onSelectRoom = (room) => {
    const meta = {};
    if (allHouse) meta.allHouse = true;

    onSelectCard(room, meta);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 0 20px',
        border: `2px solid`,
        borderColor: `${isSelected ? 'orange.main' : '#D8D8D8'}`,
        borderRadius: '20px',
        flex: 1,
        width: '100%',
        maxWidth: '320px',
        marginRight: '20px',
        cursor: 'pointer',

        '&:last-child': {
          marginRight: '0',
        },

        [theme.breakpoints.down('sm')]: {
          flexDirection: 'row',
          marginBottom: '14px',
          maxWidth: 'unset',
          padding: '20px',
          marginRight: '0',
        },
      }}
      ref={cardWrapperRef}
      onClick={() => {
        onSelectRoom(roomType);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          maxWidth: '62px',
          marginBottom: '14px',

          '& svg': {
            width: '60px',
            height: '60px',

            [theme.breakpoints.down('sm')]: {
              width: '30px',
              height: '30px',
            },
          },

          [theme.breakpoints.down('sm')]: {
            maxWidth: '30px',
            marginBottom: '0',
            marginRight: '11px',
          },
        }}
      >
        {RoomIcon && (
          <RoomIcon
            fill={
              isSelected ? theme.palette.orange.main : theme.palette.greys.dark
            }
          />
        )}
      </Box>

      <Box>
        <Typography
          variant="h2"
          sx={{
            marginBottom: description ? '10px' : '15px',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
              fontSize: '20px',
              marginBottom: '0',
              textAlign: 'unset',
            },
          }}
        >
          {name}
        </Typography>

        {description && (
          <Typography
            variant="h2"
            align="center"
            mb={2}
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: '#8A9099',

              [theme.breakpoints.down('sm')]: {
                marginBottom: '0',
                fontSize: '15px'
              },
            }}
          >
            {' '}
            {description}{' '}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: '20px',
          [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
            marginBottom: '0',
          },
        }}
      >
        {supportsMultipleRooms && (!isMobile || (isMobile && isSelected)) && (
          <CounterButton
            max={3}
            onCounterChange={(counter) => {
              onRoomQuantityChange(roomType, counter);
            }}
            value={roomType.quantity}
          />
        )}
      </Box>

      <Box
        sx={{
          marginTop: 'auto',
          color: 'orange.main',
          [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
          },

          '& .MuiSvgIcon-root': {
            width: '40px',
            height: '40px',

            [theme.breakpoints.down('sm')]: {
              width: '30px',
              height: '30px',
            },
          },
        }}
      >
        {allHouse ? (
          <Radio
            checked={isSelected}
            name="radio-buttons"
            inputProps={{ 'aria-label': 'B' }}
          />
        ) : (
          <Checkbox checked={isSelected} checkedIcon={<TickIcon />} />
        )}
      </Box>
    </Box>
  );
};

export default memo(RoomTypeCard);
