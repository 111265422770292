import React, { useEffect, useState } from 'react';
import { Box, TextareaAutosize, Typography } from '@mui/material';
import Button from '../UI/Button/Button';
import UploadInputBox from '../UI/UploadInputBox/UploadInputBox';
import { pagesActions } from '../../redux/pages-slice';
import { useDispatch, useSelector } from 'react-redux';
import { iconsDictionary } from '../../utils/icons';
import theme from '../../theme/Theme';
import {
  deleteRoomImage,
  getHubspotFormEndpoint,
  submitHubspotForm,
  uploadRoomImage,
} from '../../utils/analytics';
import {
  HUBSPOT_CONTACT_FIELDS,
  HUBSPOT_PROJECT_FIELDS,
} from '../../consts/analytics';

const RoomSummaryCard = ({
  room: { id, name = '', notes = '', media = [], type = '' },
  onSave = () => {},
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const { email } = useSelector((state) => state.pages.contactDetails);
  const { rooms } = useSelector((state) => state.pages.rooms);
  const {
    setRoomMedia,
    setRoomNotes,
    deleteRoomMedia,
    setRoomMediaSlotLoading,
  } = pagesActions;
  const RoomIcon = iconsDictionary?.[type];

  const onClickEdit = () => {
    if (editMode) {
      onSave();
    }

    setEditMode((prev) => !prev);
  };

  const detectIsTouched = () => {
    const isMediaExist = media.some((slot) => Object?.keys?.(slot)?.length > 0);

    if (notes?.length || isMediaExist) setIsTouched(true);
  };

  const onInputMediaChange = async ({ uploadedFile, mediaSlotIndex }) => {
    try {
      dispatch(
        setRoomMediaSlotLoading({ id, mediaSlotIndex, loadingState: true })
      );

      if (!isTouched) setIsTouched(true);

      let formData = new FormData();
      formData.append('file', uploadedFile);

      const isFileExist = media.some(
        (currFile) => currFile.name === uploadedFile.name
      );

      if (isFileExist) return;

      const fileFromHubspot = await uploadRoomImage(formData);
      fileFromHubspot.name = uploadedFile.name;

      dispatch(
        setRoomMedia({ id, uploadedFile: fileFromHubspot, mediaSlotIndex })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onInputMediaDelete = async ({ fileId, mediaSlotIndex }) => {
    await deleteRoomImage(fileId);

    dispatch(deleteRoomMedia({ roomId: id, mediaSlotIndex }));
  };

  const onNotesChange = (e) => {
    if (!isTouched) setIsTouched(true);

    const value = e?.target?.value;

    dispatch(setRoomNotes({ id, value }));
  };

  const submitRoomsPhotosToHubspot = async () => {
    const currentSlotsWithMedia = rooms
      .map((room) =>
        room.media.filter((slot) => !!slot.url).map((slot) => slot.url)
      )
      .flat();

    const urlsString = JSON.stringify(currentSlotsWithMedia);

    const url = getHubspotFormEndpoint('contacts', 'create');
    const properties = {
      [HUBSPOT_CONTACT_FIELDS.email]: email,
      [HUBSPOT_PROJECT_FIELDS.projectFiles]: urlsString,
    };

    const data = { pageName: 'Summary Page', properties };
    await submitHubspotForm(url, data);
  };

  useEffect(() => {
    detectIsTouched();
  }, []);

  useEffect(() => {
    if (!editMode) return;

    submitRoomsPhotosToHubspot();
  }, [media]);

  return (
    <Box
      sx={{
        maxWidth: '497px',
        border: '1px solid #DAD8D2',
        marginBottom: '22px',
        borderRadius: '20px',
        padding: '20px',
        position: editMode ? 'absolute' : 'relative',
        background: 'white',
        zIndex: editMode ? 15 : 'unset',
        boxShadow: editMode ? '0px 25px 50px rgba(0, 0, 0, 0.3)' : 'none',
        transition: editMode ? '.2s ease all' : 'none',

        [theme.breakpoints.down('sm')]: {
          padding: '10px 10px 10px',
          maxWidth: '100%',
          position: 'relative',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: `${editMode ? '20px' : ''}`,
        }}
      >
        <Box
          sx={{
            marginRight: '16px',
            [theme.breakpoints.down('sm')]: {
              paddingLeft: '10px',
            },

            '& svg': {
              display: 'block',
              width: '50px',
              height: '50px',

              [theme.breakpoints.down('sm')]: {
                width: '30px',
                height: '30px',
              },
            },
          }}
        >
          {RoomIcon && <RoomIcon fill={'#CF6F4C'} />}
        </Box>

        <Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: '700',
              textTransform: 'none',
              [theme.breakpoints.down('sm')]: {
                fontWeight: '400',
              },
            }}
          >
            {name}
          </Typography>
        </Box>

        {!editMode && !isTouched ? (
          <Box
            sx={{
              marginLeft: 'auto',
              boxShadow: 'none',
              fontSize: '18px',
              textTransform: 'none',
              border: '2px dashed',
              borderColor: 'greys.light',
              borderRadius: '10px',
              width: '100%',
              maxWidth: '200px',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch',
              cursor: 'pointer',
              [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                width: 'unset',
                padding: '15px 10px',
              },
            }}
            onClick={onClickEdit}
          >
            Add Notes &#38; Photos
          </Box>
        ) : (
          <Button
            text={editMode ? 'Done' : 'Edit'}
            onClick={onClickEdit}
            sx={{
              maxWidth: '110px',
              width: '100%',
              overflow: 'hidden',
              maxHeight: '51px',
              marginLeft: 'auto',
              boxShadow: 'none',
              boxSizing: 'border-box',
              fontSize: '16px',
              padding: '15px',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100px',
              },
            }}
          />
        )}
      </Box>

      {editMode && (
        <>
          <Box sx={{ marginBottom: '20px' }}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              placeholder="Add notes..."
              style={{
                border: '1px solid #C4C7CC',
                borderRadius: '10px',
                padding: '20px',
                fontFamily: 'Roobert',
                fontSize: '18px',
                width: '100%',
                maxWidth: '100%',
              }}
              value={notes}
              onChange={onNotesChange}
            />
          </Box>
          <Box>
            <Typography
              variant="body1"
              sx={{
                marginBottom: '7px',
                [theme.breakpoints.down('sm')]: {
                  marginBottom: '4px',
                },
              }}
            >
              Upload photos & videos of your property:
            </Typography>
            <Typography
              variant="body"
              sx={{
                color: 'greys.mid',
                marginBottom: '15px',
                display: 'block',

                [theme.breakpoints.down('sm')]: {
                  marginBottom: '20px',
                },
              }}
            >
              Upload up to 5 files. Max file size: 10 MB
            </Typography>

            <Box
              sx={{
                display: 'flex',
                '& > div': {
                  marginRight: '20px',

                  '&:last-child': {
                    marginRight: '0',
                  },

                  [theme.breakpoints.down('sm')]: {
                    marginRight: 'auto',
                  },
                },
              }}
            >
              {media.map((mediaSlot, index) => (
                <UploadInputBox
                  onChange={({ uploadedFile }) =>
                    onInputMediaChange({ uploadedFile, mediaSlotIndex: index })
                  }
                  onDelete={({ fileId }) =>
                    onInputMediaDelete({ fileId, mediaSlotIndex: index })
                  }
                  data={mediaSlot}
                />
              ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default RoomSummaryCard;
