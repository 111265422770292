import React from 'react';
import { Box } from '@mui/system';
import BackButton from './BackButton/BackButton';
import TopBar from './TopBar/TopBar';
import theme from '../../theme/Theme';
import { useLocation } from 'react-router-dom';

const Layout = (props) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <TopBar />

      <Box component={'main'} 
        sx={{ 
          position: 'relative', 
          padding: '0 40px',
          [theme.breakpoints.down('sm')]: {
            padding: '0 20px',
          },
        }}>
        {pathname !== '/welcome' && pathname !== '/no-service-thank-you' && (
          <Box
            sx={{
              position: 'absolute',
              top: '27px',
              
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }}
          >
            <BackButton
              styling={{
                position: 'relative',
                left: '-40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#DAD8D2',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                cursor: 'pointer',
                maxWidth: '65px',
                padding: '12px',
              }}
            />
          </Box>
        )}

        {props.children}
      </Box>
    </>
  );
};

export default Layout;
