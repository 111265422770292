import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HUBSPOT_MARKETING_FIELDS } from '../consts/analytics';

const useMarketingParams = () => {
  const [searchParams, _] = useSearchParams();
  const [params, setParams] = useState({});

  useEffect(() => {
    const response = {};
    const desiredSearchParams = {
      utm_source: searchParams.get(HUBSPOT_MARKETING_FIELDS.utm_source),
      utm_medium: searchParams.get(HUBSPOT_MARKETING_FIELDS.utm_medium),
      utm_campaign: searchParams.get(HUBSPOT_MARKETING_FIELDS.utm_campaign),
      utm_term: searchParams.get(HUBSPOT_MARKETING_FIELDS.utm_term),
      gclid: searchParams.get(HUBSPOT_MARKETING_FIELDS.google_analytics_click_id),
    };

    Object.entries(desiredSearchParams).forEach(([key, value]) => {
      if (value) response[key] = value;
    });

    setParams(response);
  }, []);

  return params;
};

export default useMarketingParams;
