import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '../UI/Button/Button';
import RoomSummaryCard from '../RoomSummaryCard/RoomSummaryCard';
import theme from '../../theme/Theme';
import { pagesActions } from '../../redux/pages-slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  HUBSPOT_CONTACT_FIELDS,
  HUBSPOT_PROJECT_FIELDS,
} from '../../consts/analytics';
import {
  getHubspotFormEndpoint,
  prepareProjectNotesForHubspot,
  sendGtagEvent,
  submitHubspotForm,
} from '../../utils/analytics';

const InfoText = ({ field, value }) => (
  <>
    <Typography
      variant="body1"
      sx={{ fontWeight: 500, color: 'greys.dark', marginBottom: '5px' }}
    >
      {field}
    </Typography>

    <Typography
      variant="h2"
      sx={{
        [theme.breakpoints.down('sm')]: {
          fontSize: '16px',
        },
      }}
    >
      {value}
    </Typography>
  </>
);

const SummaryDetails = ({
  fullName = '',
  email = '',
  phone = '',
  zipCode = '',
  startDate = '',
  budgetRange = '',
  notes = '',
  rooms = [],
}) => {
  const dispatch = useDispatch();
  const [isTouched, setIsTouched] = useState(false);
  const selectedRooms = rooms.filter((room) => room.isSelected);
  const { setProjectNotes } = pagesActions;

  const onSummaryDetailsSave = async () => {
    const notesString = prepareProjectNotesForHubspot({
      projectNotes: notes,
      rooms,
    });

    const properties = {
      [HUBSPOT_CONTACT_FIELDS.email]: email,
      [HUBSPOT_PROJECT_FIELDS.notes]: notesString,
    };
    const gtagData = {
      event: 'SummeryEdited',
    };

    const url = getHubspotFormEndpoint('contacts', 'create');

    const data = { pageName: 'Summary Page', properties };

    await submitHubspotForm(url, data);
    sendGtagEvent(gtagData);

    setIsTouched(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
          },
        }}
      >
        <Box
          sx={{
            marginBottom: '31px',
            [theme.breakpoints.down('sm')]: {
              marginBottom: '20px',
            },
          }}
        >
          <Typography variant="h2" sx={{ fontWeight: 700 }}>
            Your Information
          </Typography>
        </Box>

        <Box>
          <Box sx={{ 
            display: 'flex', 
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
              marginBottom: '10px',
            }
          }}>
            <Box sx={{ flex: '1' }}>
              <Box
                sx={{
                  marginBottom: '16px',
                  [theme.breakpoints.down('sm')]: {
                    marginBottom: '24px',
                  },
                }}
              >
                <InfoText field={'Full Name'} value={fullName} />
              </Box>

              <Box
                sx={{
                  marginBottom: '16px',
                  [theme.breakpoints.down('sm')]: {
                    marginBottom: '24px',
                  },
                }}
              >
                <InfoText field={'Email'} value={email} />
              </Box>

              <Box
                sx={{
                  marginBottom: '16px',
                  [theme.breakpoints.down('sm')]: {
                    marginBottom: '24px',
                  },
                }}
              >
                <InfoText field={'Phone Number'} value={phone} />
              </Box>
            </Box>
            <Box sx={{ flex: '1' }}>
              <Box
                sx={{
                  marginBottom: '16px',
                  [theme.breakpoints.down('sm')]: {
                    marginBottom: '24px',
                  },
                }}
              >
                <InfoText field={'Zip-Code'} value={zipCode} />
              </Box>

              <Box
                sx={{
                  marginBottom: '16px',
                  [theme.breakpoints.down('sm')]: {
                    marginBottom: '24px',
                  },
                }}
              >
                <InfoText field={'Approximate start'} value={startDate} />
              </Box>

              <Box>
                <InfoText field={'Approximate Budget'} value={budgetRange} />
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginBottom: '19px' }}>
            <Typography
              variant="body1"
              sx={{ marginBottom: '16px', color: 'greys.dark', fontWeight: '500' }}
            >
              Have any notes to share?
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Add notes..."
              value={notes}
              onChange={(e) => {
                setIsTouched(true);
                dispatch(setProjectNotes(e.target.value));
              }}
              style={{
                width: '100%',
                maxWidth: '497px',
                border: '1px solid #C4C7CC',
                borderRadius: '10px',
                padding: '20px',
                fontFamily: 'Roobert',
                fontSize: '18px',
              }}
            />
          </Box>

          <Box>
            <Button
              sx={{
                maxWidth: '100px',
                maxHeight: '50px',
                boxSizing: 'border-box',
                padding: '10px 20px',
                fontSize: '16px',
                minWidth: '170px'
              }}
              text={'SAVE NOTES'}
              disabled={!isTouched}
              onClick={onSummaryDetailsSave}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: '26px',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
              marginBottom: '20px',
            },
          }}
        >
          Scope of Work
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          {selectedRooms.map((room) => (
            <RoomSummaryCard
              onSave={onSummaryDetailsSave}
              setIsTouched={setIsTouched}
              room={room}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryDetails;
