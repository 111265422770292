import React from 'react';

let source,campaign,medium,term,clientId;


function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function getTrackerId(){
  try {
    var tracker = window.ga.getAll()[0];
    return tracker.get('clientId');
  } catch(e) {
    return 'n/a';
  }
}

const getTracking = () => {
  const campaignValue = getCookie('__gtm_campaign_url');
  const referralValue = getCookie('__gtm_referrer');
  const clientIdFromCookie = getCookie ('_ga');
  const clientIdFromTracker = getTrackerId();

  if (campaignValue !== ''){
    const campaignValueDecoded = decodeURIComponent(campaignValue)
    const url = new URL (campaignValueDecoded);
    source = url.searchParams.get('utm_source');
    campaign = url.searchParams.get('utm_campaign');
    medium = url.searchParams.get('utm_medium');
    term = url.searchParams.get('utm_term');
  }
  else if (referralValue !== ''){
    const referralValueDecoded = decodeURIComponent(referralValue)
    const url = new URL (referralValueDecoded);
    source = url.hostname;
    medium = 'referral';
  }

  if (typeof clientIdFromCookie !== undefined){
    clientId = clientIdFromCookie.split('.').slice(-2).join(".")
    console.log('getting analytics info from cookie');
  }
  if (clientIdFromTracker !== 'n/a'){
    console.log('getting analytics info from tracker');
    clientId = clientIdFromTracker;
  }

  const trackingParam = {
        source: source,
        campaign: campaign,
        medium: medium,
        term: term,
        clientId: clientId,
      }
  return trackingParam;
};

export default getTracking;