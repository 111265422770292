import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { sendGtagEvent } from '../utils/analytics';

export const usePageViewGtag = (cb) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const event = {
      event: 'page_view',
      page: pathname,
    };

    sendGtagEvent(event);
  }, [pathname]);
};
