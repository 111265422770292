import React, { useEffect } from 'react';
import { useQueryParams } from '../hooks/useQueryParams';
import { Box } from '@mui/system';
import Stepper from '../components/UI/Stepper/Stepper';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { pagesActions } from '../redux/pages-slice';
import ImageMasonry from '../components/UI/ImageMasonry/ImageMasonry';
import theme from '../theme/Theme';
import {
  HUBSPOT_CONTACT_FIELDS,
  HUBSPOT_PROJECT_FIELDS,
} from '../consts/analytics';
import {
  getDesignsStyles,
  getHubspotFormEndpoint,
  sendGtagEvent,
  submitHubspotForm,
} from '../utils/analytics';

const Gallery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const designs = useSelector((state) => state.pages.designs) || [];
  const { email } = useSelector((state) => state.pages.contactDetails) || '';
  const { setPickedStyles, setDesignsImages } = pagesActions;
  const nextDisabled = !designs.some((design) => design.isSelected);

  const onClickNext = async () => {
    const selectedDesigns = [
      ...new Set(
        designs
          .filter((design) => design.isSelected)
          .map((selected) => selected.title)
      ),
    ];

    const isOnlyOneDesign = selectedDesigns.length === 1;
    const selectedDesignsString = selectedDesigns.join(', ');

    const selectedDesignsImages = designs
      .filter((design) => design.isSelected)
      .map((design) => design.img);

    const desiredSelectedDesignField = isOnlyOneDesign
      ? HUBSPOT_PROJECT_FIELDS.preferredDesignStyle
      : HUBSPOT_PROJECT_FIELDS.preferredDesignStyleOther;

    const properties = {
      [HUBSPOT_CONTACT_FIELDS.email]: email,
      [desiredSelectedDesignField]: selectedDesignsString,
    };
    const gtagData = {
      event: 'mhDesignSubmitted',
      designs: JSON.stringify(selectedDesignsImages),
    };

    const url = getHubspotFormEndpoint('contacts', 'create');

    const data = { pageName: 'Preferred Design Styles', properties };

    await submitHubspotForm(url, data);
    sendGtagEvent(gtagData);

    navigate(`/summary${queryParams}`);
  };

  const onStyleSelect = (item) => {
    dispatch(setPickedStyles(item));
  };

  useEffect(() => {
    const fetchStyles = async () => {
      const designs = await getDesignsStyles();

      dispatch(setDesignsImages(designs));
    };

    if (!designs.length) {
      fetchStyles();
    }
  }, []);

  if (!designs?.length) return <span>loading...</span>;

  return (
    <>
      <Box sx={{ padding: '53px 40px 20px',


[theme.breakpoints.down('sm')]: {
           padding: '15px 0px 20px'
          }

    }}>
        <Box sx={{ marginTop: '15px', marginBottom: '30px' }}>
          <Stepper activeStep={2} />
        </Box>

        <Typography variant="h1" align="center" sx={{ marginBottom: '7px' }}>
          Which of these designs inspire you?
        </Typography>

        <Typography
          align="center"
          variant="h2"
          sx={{
            color: 'orange.main',
            marginBottom: '24px',
            fontWeight: 400,
            [theme.breakpoints.down('sm')]: {
              fontSize: '16px',
              marginBottom: '38px',
            },
          }}
        >
          Check as many as you like
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {<ImageMasonry items={designs} onSelectItem={onStyleSelect} />}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          background: 'white',
          padding: '20px 0',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F8F5F3 49.48%)',
          height: '200px',
          alignItems: 'flex-end',
        }}
      >
        <Button
          sx={{
            padding: '20px 75px',
            color: 'common.white',
            borderRadius: '10px',
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default Gallery;
